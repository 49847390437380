import React from "react"
import { connect } from "react-redux"
import { Loader, Popover, Text, UnstyledButton } from "@mantine/core"

import { updateFormattedTargetedMessage } from "QuorumGrassroots/campaign-forms/action-creators"

import {
    MessagePreview,
    EditVisualizationBar,
    VisualizationMode,
    EditMode,
    SaveButton,
} from "QuorumGrassroots/framework/components/EditableMessagePreview/style"
import { useFetchPlaceholdersFromMessage } from "QuorumGrassroots/services/grassrootsCampaign"
import {
    generateTargetedMessage,
    validateMessageHasPlaceholders,
} from "QuorumGrassroots/framework/components/EditableMessagePreview/helper"
import { reinsertPlaceholders, replaceEditablePlaceholders } from "QuorumGrassroots/helperFunctions"
import { HighlightedTextarea } from "QuorumGrassroots/framework/components/HighlightedTextarea/HighlightedTextarea"

const UnconnectedEditableMessagePreview = (props) => {
    const {
        t,
        currentTargetedMessage,
        selectedTargetId,
        isLoading,
        setWrongPlaceholder,
        wrongPlaceholder,
        isEditEnabled,
    } = props
    const inputValue = props.isVisualizationMode ? currentTargetedMessage?.formatted?.content : props.input.value

    const updatePlaceholdersMutation = useFetchPlaceholdersFromMessage(selectedTargetId)

    const handleUpdatePlaceholders = async (content) => {
        const hasPlaceholders = validateMessageHasPlaceholders(content)
        if (!hasPlaceholders && !selectedTargetId) {
            const targetedMessage = generateTargetedMessage(currentTargetedMessage, content)
            return props.isQueryMode
                ? props.updateFormattedMaMessage(parseInt(props.activeGroupId), targetedMessage)
                : props.updateFormattedTargetedMessage(parseInt(props.activeGroupId), targetedMessage)
        }

        const contentWithPlaceholders = reinsertPlaceholders(content)
        const contentWithUserReadablePlaceholders = replaceEditablePlaceholders(content)

        return updatePlaceholdersMutation
            .mutateAsync({
                content: contentWithPlaceholders,
                person_id: selectedTargetId,
            })
            .then((response) => {
                const newTargetedMessage = {
                    raw: {
                        ...currentTargetedMessage.raw,
                        content: contentWithUserReadablePlaceholders,
                    },
                    formatted: {
                        ...currentTargetedMessage.formatted,
                        content: replaceEditablePlaceholders(response.content),
                    },
                }

                props.isQueryMode
                    ? props.updateFormattedMaMessage(props.activeGroupId, newTargetedMessage)
                    : props.updateFormattedTargetedMessage(parseInt(props.activeGroupId), newTargetedMessage)
            })
    }

    const handleSave = () => {
        handleUpdatePlaceholders(inputValue).finally(() => {
            props.setIsVisualizationMode((prevState) => !prevState)
        })
    }

    const showLoading = isLoading || updatePlaceholdersMutation.isLoading
    const showEditModeButton = props.isVisualizationMode && !showLoading
    const showPreviewModeButton = !props.isVisualizationMode && !showLoading

    return (
        <MessagePreview isQueryMode={props.isQueryMode}>
            <HighlightedTextarea
                {...props}
                value={inputValue}
                onChange={props.input.onChange}
                onBlur={props.input.onBlur}
                setWrongPlaceholder={setWrongPlaceholder}
            />
            {isEditEnabled && (
                <EditVisualizationBar>
                    {showLoading && (
                        <VisualizationMode>
                            <Loader size={20} />
                        </VisualizationMode>
                    )}

                    {showEditModeButton && (
                        <VisualizationMode>
                            <UnstyledButton
                                type="button"
                                data-cy="edit-visualization-button"
                                onClick={() => props.setIsVisualizationMode((prevState) => !prevState)}
                            >
                                <i className="fa fa-pencil" aria-hidden="true" />
                            </UnstyledButton>
                            <VisualizationModeTooltip text={t("campaign.write.visualization_mode_tooltip")} />
                        </VisualizationMode>
                    )}

                    {showPreviewModeButton && (
                        <EditMode>
                            <SaveButton
                                color="green"
                                variant="light"
                                radius="xl"
                                size="sm"
                                compact
                                leftIcon={<i className="fa fa-check" aria-hidden="true" />}
                                data-cy="save-edit-button"
                                onClick={handleSave}
                                isLoading={updatePlaceholdersMutation.isLoading}
                                disabled={updatePlaceholdersMutation.isLoading || wrongPlaceholder}
                            >
                                Save
                            </SaveButton>
                            <Text color="gray" size="xs" weight={400}>
                                {t("campaign.write.edit_visualization_mode")}
                            </Text>
                        </EditMode>
                    )}
                </EditVisualizationBar>
            )}
        </MessagePreview>
    )
}

const VisualizationModeTooltip = ({ text }) => {
    return (
        <Popover width={300} position="top-end" withArrow shadow="md" offset={3}>
            <Popover.Target>
                <UnstyledButton>
                    <i className="fa fa-info-circle" aria-hidden="true" />
                </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown>
                <Text color="gray" size="sm" weight={400}>
                    {text}
                </Text>
            </Popover.Dropdown>
        </Popover>
    )
}

export const EditableMessagePreview = connect(null, { updateFormattedTargetedMessage })(
    UnconnectedEditableMessagePreview,
)
