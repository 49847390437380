import PropTypes from "prop-types"
import React, { useMemo, useRef, useState } from "react"
import { Field } from "redux-form/immutable"
import { connect } from "react-redux"
import { submit } from "redux-form"
import { Skeleton } from "@mantine/core"

import WriteMessageFormSection from "QuorumGrassroots/campaign-forms/components/WriteMessageFormSection"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { StyledGroupSelector } from "QuorumGrassroots/styled-components/components/StyledGroupSelector"
import { StyledLargeBoldText } from "QuorumGrassroots/widgets/styled-components/styles"
import { InputWithLock } from "QuorumGrassroots/styled-components/components/InputWithLock"
import { writeSectionConfigs } from "QuorumGrassroots/campaign-forms/write-form-constants"
import { InputField } from "app/static/frontend/forms/components/InputField"
import { shouldShowOrganizationField } from "QuorumGrassroots/campaign-forms/helpers"
import { MultiSelect } from "QuorumGrassroots/styled-components/components/MultiSelect"
import { MultiSelectWithLock } from "QuorumGrassroots/campaign-forms/components/ReusableForms/GenericWriteForm"
import { toggleTargetOneClick, updateFormattedTargetedMessage } from "QuorumGrassroots/campaign-forms/action-creators"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"
import { StyledSkeletonLoading } from "QuorumGrassroots/styled-components/components/StyledSkeletonLoading"
import { WriteFormFieldsContainer } from "QuorumGrassroots/campaign-forms/components/CampaignMessagePreview/styles"
import { reinsertPlaceholders, replaceEditablePlaceholders } from "QuorumGrassroots/helperFunctions"
import { useFetchPlaceholdersFromMessage } from "QuorumGrassroots/services/grassrootsCampaign"
import {
    generateTargetedMessage,
    validateMessageHasPlaceholders,
} from "QuorumGrassroots/framework/components/EditableMessagePreview/helper"
import { useDebouncedCallback } from "QuorumGrassroots/framework/hooks/useDebouncedCallback"

const REGISTRATION_FORM_NAME = "registration"

const { CampaignType } = DjangIO.app.grassroots.campaign.types

const UnconnectedCampaignMessagePreview = ({
    campaignSubject,
    campaignMessage,
    combinedCampaignMessages,
    isCombinedCampaign,
    messageGroups,
    targetedMessages,
    t,
    dispatch,
    ...props
}) => {
    const lastOfficialIdTargetedRef = useRef(null)
    const hasCustomRecipients = messageGroups.some((message) => message.custom_email_targets?.length > 0)
    const isFFAddressLookupEnabled = isFeatureEnabled("ff_ngg_address_lookup")
    const addressLookupEnabled = isFFAddressLookupEnabled && !hasCustomRecipients
    const hasNoOfficialsForAddress = props.officialsPreview?.length === 0
    const [wrongPlaceholder, setWrongPlaceholder] = useState("")

    const messageGroupsIdsWithOfficials =
        props.officialsPreview?.length > 0
            ? [...new Set(props.officialsPreview.map((official) => parseInt(official.message_group_id)))]
            : []

    const isCommentOnRegulation = props.campaign.campaign_type === CampaignType.comment_on_regulation.value
    const isWriteALetter = props.campaign.campaign_type === CampaignType.write_member.value
    const areTargetsLoading = isWriteALetter && props.officialsPreview === null
    const hasAddressField = !!props.registeredFields?.hasOwnProperty("input_address")

    const renderSubmitButton = () => {
        const getSubmitText = () => {
            if (addressLookupEnabled && !hasTargetsSelected && isWriteALetter && !!currentTargets.length)
                return t("campaign.write.need_selection")
            if (props.registrationSubmitButtonText) return props.registrationSubmitButtonText
            if (props.submitting) return t("form.submitting")
            return t("campaign.write.submit_all_letters")
        }

        const hasOfficialsByTheAddress = props.officialsPreview?.length > 0
        return (
            <StyledButton
                className="submit-button"
                onClick={() => dispatch(submit(REGISTRATION_FORM_NAME))}
                disabled={
                    props.submitting ||
                    (addressLookupEnabled &&
                        hasAddressField &&
                        hasOfficialsByTheAddress &&
                        (areTargetsLoading || !hasTargetsSelected || wrongPlaceholder))
                }
                data-cy="message-preview-submit-button"
                type="submit"
                isCampaignPage
                activateNGGTheme
            >
                {getSubmitText()}
            </StyledButton>
        )
    }

    const activeMessageGroups =
        addressLookupEnabled && hasAddressField
            ? messageGroups.filter((messageGroup) => messageGroupsIdsWithOfficials.includes(messageGroup.id))
            : messageGroups

    const activeGroupId = useMemo(() => {
        if (!isWriteALetter) return
        return props.activeGroupId ?? (activeMessageGroups.length > 0 && String(activeMessageGroups[0].id))
    }, [isWriteALetter, activeMessageGroups, props.activeGroupId])

    const currentTargets = useMemo(() => {
        if (!isWriteALetter) return

        const officials = props.officialsPreview?.length > 0 ? props.officialsPreview : []

        const filteredOfficials = officials.filter((official) => {
            return String(official.message_group_id) === String(activeGroupId)
        })

        // Sort by `targeted` key (true first, false last)
        return filteredOfficials.sort((a, b) => b.targeted - a.targeted)
    }, [activeGroupId, isWriteALetter, props.officialsPreview])

    const currentTargetedMessage = useMemo(() => {
        if (!isWriteALetter) return
        return targetedMessages?.[activeGroupId]
    }, [activeGroupId, isWriteALetter, targetedMessages])

    const selectedTargets = useMemo(
        () => currentTargets?.filter((targets) => targets.targeted).map((target) => target.value),
        [currentTargets],
    )
    const firstTargetOfficialId = useMemo(() => selectedTargets?.[0], [selectedTargets])

    const hasTargetsSelected = selectedTargets?.length > 0

    const updatePlaceholdersMutation = useFetchPlaceholdersFromMessage(firstTargetOfficialId)
    const handleUpdatePlaceholders = useDebouncedCallback(async (content, officialId) => {
        if (!isFeatureEnabled("ff_ngg_message_preview")) return

        const hasPlaceholders = validateMessageHasPlaceholders(content)
        if (!hasPlaceholders || !officialId) {
            props.updateFormattedTargetedMessage(
                parseInt(activeGroupId),
                generateTargetedMessage(currentTargetedMessage, content),
            )
            return
        }

        const currentMessagePreview = props.formValues?.[activeGroupId]
        await updatePlaceholdersMutation
            .mutateAsync({
                content: reinsertPlaceholders(content),
                fixed_pre_content: reinsertPlaceholders(currentMessagePreview?.prebody || ""),
                fixed_post_content: reinsertPlaceholders(currentMessagePreview?.postbody || ""),
                person_id: officialId,
            })
            .then((response) => {
                props.updateFormattedTargetedMessage(parseInt(activeGroupId), {
                    ...currentTargetedMessage,
                    formatted: {
                        ...response,
                        content: replaceEditablePlaceholders(response.content),
                    },
                })
            })
    }, 500)

    const onChangeTargets = async (officialIds) => {
        if (!isWriteALetter) return

        const officialId = officialIds[0]
        const value = props.formValues?.[activeGroupId]?.body ?? ""
        const lastOfficialIdTargeted = lastOfficialIdTargetedRef.current

        const firstOfficialHasChanged = lastOfficialIdTargeted !== officialId
        if (firstOfficialHasChanged) {
            await handleUpdatePlaceholders(value, officialId)
            lastOfficialIdTargetedRef.current = officialId
        }
        props.toggleTargetOneClick(
            props.uniqueWidgetId,
            officialIds,
            props.activeGroupId ?? String(messageGroups[0].id),
        )
    }

    const isMultiSelectedUnlocked = useMemo(() => props.campaign?.supporters_can_choose_targets, [props.campaign])
    const MultiSelectComponent = useMemo(
        () => (isMultiSelectedUnlocked ? MultiSelect : MultiSelectWithLock),
        [isMultiSelectedUnlocked],
    )

    const draftOption = useMemo(() => {
        return messageGroups?.length
            ? messageGroups?.find((group) => Number(group.id) === Number(props.activeGroupId ?? messageGroups[0].id))
                  ?.advocate_editing_permission
            : props.campaign.draft_requirements
    }, [props.activeGroupId, messageGroups, props.campaign.draft_requirements])
    if (isCombinedCampaign) {
        return (
            <>
                {combinedCampaignMessages.map((campaign, idx) => (
                    <div className="message-preview-combined">
                        <StyledLargeBoldText>{campaign.name}</StyledLargeBoldText>
                        {campaign.subject && (
                            <Field
                                component={InputWithLock}
                                name={`campaign-subject-${idx}`}
                                label={t("campaign.write.subject")}
                                disabled
                            />
                        )}
                        {campaign.message && (
                            <Field
                                component={InputWithLock}
                                name={`campaign-message-${idx}`}
                                label={t("campaign.write.body")}
                                disabled
                                isTextarea
                                maxRows={8}
                            />
                        )}
                    </div>
                ))}
                {renderSubmitButton()}
            </>
        )
    }

    if (messageGroups?.length > 0 || isCommentOnRegulation) {
        // global is the name of the SingleBodyMessageLayout form. Just keeping it consistent for campaigns without groups
        const innerFormName = messageGroups?.length ? activeGroupId : "global"
        const hasTargets = currentTargets?.length > 0
        const shouldShowMessagePreview = addressLookupEnabled && hasAddressField ? isWriteALetter && hasTargets : true
        const shouldShowMessageGroupSelector = messageGroups?.length > 1 && shouldShowMessagePreview

        return (
            <>
                <WriteFormFieldsContainer withTopMargin={addressLookupEnabled && isWriteALetter}>
                    {shouldShowMessageGroupSelector && (
                        <div className="form-input-field">
                            <StyledGroupSelector
                                value={activeGroupId}
                                onChange={(groupId) => props.changeMessageGroup(props.uniqueWidgetId, groupId)}
                                data={activeMessageGroups.map((group) => ({
                                    value: String(group.id),
                                    label: group.name,
                                }))}
                            />
                        </div>
                    )}

                    {areTargetsLoading && (
                        <StyledSkeletonLoading>
                            {[...Array(6)].map((_, index) => (
                                <div key={index}>
                                    <Skeleton height={25} circle />
                                    <Skeleton height={25} radius="md" />
                                </div>
                            ))}
                        </StyledSkeletonLoading>
                    )}

                    {hasTargets && (
                        <MultiSelectComponent
                            clearable
                            onChange={onChangeTargets}
                            value={selectedTargets}
                            data={currentTargets}
                            label={`Officials (${selectedTargets?.length})`}
                            withBottomMargin
                            // we are setting this margin top because unlocked component
                            // doesn't has the .form-input-field container
                            // around the component by default
                            withTopMargin={shouldShowMessageGroupSelector}
                        />
                    )}

                    {(shouldShowMessagePreview || isCommentOnRegulation) && (
                        <>
                            <WriteMessageFormSection
                                activeId={innerFormName}
                                draftOption={draftOption}
                                formName="campaignMessagePreviewForm"
                                key={innerFormName}
                                submitting={props.submitting}
                                t={t}
                                uniqueWidgetId={props.uniqueWidgetId}
                                writeSectionConfigs={writeSectionConfigs[props.campaign.campaign_type] ?? {}}
                                // props for EditableMessagePreview
                                currentTargetedMessage={currentTargetedMessage}
                                activeGroupId={activeGroupId}
                                selectedTargetId={firstTargetOfficialId}
                                isLoadingBody={updatePlaceholdersMutation.isLoading}
                                inputBodyHasEditAndVisualizationMode={
                                    isFeatureEnabled("ff_ngg_message_preview") && isWriteALetter && hasTargets
                                }
                                setWrongPlaceholder={setWrongPlaceholder}
                                wrongPlaceholder={wrongPlaceholder}
                            />
                            {shouldShowOrganizationField(props.campaign) && (
                                <Field
                                    component={InputField}
                                    formName="campaignMessagePreviewForm"
                                    name="global.submitting_org_name"
                                    label={t("campaign.comment_on_regulation.organization")}
                                    isCampaignPage
                                />
                            )}
                        </>
                    )}
                    {hasNoOfficialsForAddress && (
                        <StyledContrastText isCampaignPage>
                            {t("campaign.write.no_messages_to_send_with_login")}
                        </StyledContrastText>
                    )}
                    {wrongPlaceholder && (
                        <StyledContrastText isCampaignPage>
                            Invalid text, remove {wrongPlaceholder} in order to save
                        </StyledContrastText>
                    )}
                </WriteFormFieldsContainer>
                {renderSubmitButton()}
            </>
        )
    }

    return (
        <>
            {campaignSubject && (
                <Field
                    component={InputWithLock}
                    name="subject"
                    label={t("campaign.write.subject")}
                    disabled
                    dataCy="subject"
                />
            )}
            {campaignMessage && (
                <Field
                    component={InputWithLock}
                    name="message"
                    label={t("campaign.write.body")}
                    disabled
                    isTextarea
                    dataCy="body"
                    maxRows={8}
                />
            )}
            {renderSubmitButton()}
        </>
    )
}

UnconnectedCampaignMessagePreview.propTypes = {
    campaignMessage: PropTypes.string,
    campaignSubject: PropTypes.string,
    combinedCampaignMessages: PropTypes.array,
    isCombinedCampaign: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
}

UnconnectedCampaignMessagePreview.defaultProps = {
    campaignMessage: "",
    campaignSubject: "",
    combinedCampaignMessages: [],
}

const mapStateToProps = (state) => {
    return {
        submitting: state.form?.getIn([REGISTRATION_FORM_NAME, "submitting"]),
        officialsPreview: state.widgets.toJS().officialsPreview,
        targetedMessages: state.widgets.toJS().targetedMessages,
        registeredFields: state.form.toJS().registration.registeredFields,
    }
}

const actions = { toggleTargetOneClick, updateFormattedTargetedMessage }

export const CampaignMessagePreview = connect(mapStateToProps, actions)(UnconnectedCampaignMessagePreview)
